import React, {Suspense}  from 'react';
import logotype from '../resources/logotype-bf.svg';

import { 
  css, 
  Grid,
  Div,
  Spinner,
  Reset,
} from "@bdfx/fukit"
import ctrans from "../common/trans"
import history from "../common/history"
import LanguageSwitcher from "./language_switcher"
import { Router, Route, Switch } from "react-router-dom"
import activate from '../resources/btn-mergado-store.svg';
import Homepage from "./homepage"
import {initGA} from "../common/tracking"

export const Placeholder = () => <Div minHeight="120vh" background="white" padding="20vh" textAlign="center"><Spinner size={40} color="#ddd" type="bubbles" /></Div>

export const Lazyload = ({ loader, loading = null }) => {
    const Component = React.lazy(loader)
    return (props) => <Suspense fallback={<Placeholder />}>
                        <Component {...props} />
                    </Suspense>
}

const Result = Lazyload({
  loader: () => import(/* webpackChunkName: "result" */ './result'),
});

const ResultDetail = Lazyload({
  loader: () => import(/* webpackChunkName: "result_detail" */ './result-detail'),
});


const Conditions = Lazyload({
  loader: () => process.env.LANG === "sk" ? import(/* webpackChunkName: "conditions" */ './conditions/sk') : import(/* webpackChunkName: "conditions" */ './conditions/cz'),
});

const PageNotFound = Lazyload({
  loader: () => import(/* webpackChunkName: "not_found" */ './not-found'),
});

const Gdpr = Lazyload({
  loader: () => process.env.LANG === "sk" ? import(/* webpackChunkName: "gdpr" */ './gdpr/sk') : import(/* webpackChunkName: "gdpr" */ './gdpr/cz'),
});

interface Props {
  lang: string | undefined
}

function App({lang}: Props) {

  initGA(lang === "sk" ? 'UA-193292971-2' :'UA-193292971-1')

  return (
    <Reset>
      <Header className="header">
        <div>
        <LanguageSwitcher />

        <Grid cols="auto 1fr">
          <a onClick={(e) => { e.preventDefault(); e.stopPropagation(); history.push("/"); return false; }} href={`${process.env.PUBLIC_URL}/`}>
          <img width="300" height="60" src={logotype} alt="Logo" />
          </a>
          <Menu className="menu">
            <ul>
              <li>
                <a onClick={(e) => { e.preventDefault(); e.stopPropagation(); history.push("/ukazka_auditu/"); return false; }} href={`${process.env.PUBLIC_URL}/ukazka_auditu/`}>
                    {ctrans("example")}
                </a>
              </li>
              <li><a href={`https://bidding-fox.${process.env.LANG}`} target="_blank" rel="noreferrer">Bidding Fox</a></li>
              <li><a href={`https://bidding-fox.${process.env.LANG}/kontakt`} target="_blank" rel="noreferrer">{ctrans("contact")}</a></li>
              <li><a href={process.env.LANG === "sk" ? `https://www.bidding-fox.sk/bidding-audit-sk` : `https://www.bidding-fox.cz/bidding-audit`} target="_blank" rel="noreferrer">{ctrans("about_audit")}</a></li>
            </ul>
          </Menu>
        </Grid>
        </div>
      </Header>
      <Body className="bidding-audit">
        <Router history={history}>
          <Switch>
            <Route path={`/audit/:result_id/:detail`} component={ResultDetail} />
            <Route path={`/audit/:result_id`} component={Result} />

            <Route path={`/ukazka_auditu/:detail`} component={ResultDetail} />
            <Route path={`/ukazka_auditu/`} component={Result} />

            <Route path={process.env.LANG === "sk" ? `/podmienky` : `/podminky`} component={Conditions} />
            <Route path={`/gdpr`} component={Gdpr} />
            <Route path="/" exact={true} component={Homepage}/>
            <Route path="*" component={PageNotFound}/>
          </Switch>
        </Router>
      </Body>
      <Footer className="footer">
        <Div position="relative" zIndex={1} marginTop="-50px">
          {ctrans("footer_bf_info")}
          <a className="activate-button" href="https://store.mergado.com/detail/biddingfox/#about" target="_blank" rel="noreferrer"><img src={activate} alt="Aktivujte v Mergado store" width="300" height="75" /></a>
          <hr/>
          {ctrans("footer_legal_info")}
          <br/><br/>
          <a onClick={(e) => { e.preventDefault(); e.stopPropagation(); history.push(process.env.LANG === "sk" ? `/podmienky/` : `/podminky/`); return false; }} href={process.env.LANG === "sk" ? `/podmienky/` : `/podminky/`}>{ctrans("conditions")}</a>
          &nbsp;|&nbsp;
          <a onClick={(e) => { e.preventDefault(); e.stopPropagation(); history.push(`/gdpr`); return false; }} href={`/gdpr`}>GDPR</a>
          &nbsp;|&nbsp;
          <a href={process.env.LANG === "sk" ? `https://www.bidding-fox.sk/blog` : `https://www.bidding-fox.cz/blog`} target="_blank" rel="noreferrer">Bidding BLOG</a>
        </Div>
      </Footer>
    </Reset>
  );
}
const Header = css("header")({
  boxShadow: "0 0 15px 0 rgb(0 0 0 / 50%)",
  position: "relative",
  zIndex: 60,
  background: "white",

  "> div": {
    maxWidth: "1100px",
    margin: "auto",
    padding: "3em 1em 1em",
  },
  "img": {
    maxWidth: "300px",
  },

  "@media only screen and (max-width: 1000px)": {
    ".fuk-grid": {
      display: "block",
      textAlign: "center",
    },
    ".menu": {
      textAlign: "center",
    },
  },

})

const Body = css("section")({
})

const Footer = css("section")({
    background: "#d32c30",
    color: "white",
    padding: "50px",
    paddingTop: 0,
    fontSize: "1.2em",
    "a, a:focus, a:visited, a:active": {
      color: "white",
      textDecoration: "underline",
    },
    "hr": {
      maxWidth: "600px",
      margin: "40px auto",
    },
    "a:hover": {
      color: "white !important",
      textDecoration: "none",
    },

    ".activate-button": {
      margin: "1em",
      display: "block",
    },

    "@media only screen and (max-width: 1000px)": {
      paddingTop: "50px",
    },
})

const Menu = css("div")({
  fontSize: "1.2em",
  fontWeight: 300,
  textAlign: "right",
  marginTop: "15px",
  marginBottom: "-10px",
  "li": {
    display: "inline-block",
    paddingLeft: "0.75em",
    ":after": {
      content: "'|'",
      paddingLeft: "0.75em",
    },
    ":last-of-type:after": {
      content: "''",
    }
  },
  "a, a:visited, a:focus, a:active": {
    color: "#555",
    textDecoration: "none !important",
  },
  "a:hover": {
    textDecoration: "underline !important",
  }

})


export default App;
